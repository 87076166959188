<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Informations personnelles
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your personal informaiton</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Sauvegarder
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Annuler
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <h5 class="font-weight-bold mb-6">
          {{
            currentUserPersonalInfo.professional
              ? "Professionnel"
              : "Particulier"
          }}
        </h5>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label text-left">
                Nom
              </label>
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="currentUserPersonalInfo.lastname"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label text-left">
                Prénom
              </label>
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="currentUserPersonalInfo.firstname"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label text-left">
                Téléphone
              </label>
              <div class="input-group input-group-lg input-group-solid">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="la la-phone"></i>
                  </span>
                </div>
                <input
                  ref="phone"
                  type="text"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="Phone"
                  v-bind:value="currentUserPersonalInfo.phone"
                />
              </div>
              <span class="form-text text-muted"
                >We'll never share your phone with anyone else.</span
              >
            </div>
          </div>
        </div>

        <h5 class="font-weight-bold mt-10 mb-6">
          Informations de facturation
        </h5>
        <template v-if="currentUserPersonalInfo.professional">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label text-left">
                  Dénomination
                </label>
                <input
                  ref="name"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  v-bind:value="currentUserPersonalInfo.denomination"
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label text-left">
                  TVA/Siret
                </label>
                <input
                  ref="name"
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  v-bind:value="currentUserPersonalInfo.vat"
                />
              </div>
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label text-left">
                Adresse 1
              </label>
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="currentUserPersonalInfo.address1"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label text-left">
                Adresse 2
              </label>
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="currentUserPersonalInfo.address2"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label text-left">
                Pays
              </label>
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="currentUserPersonalInfo.country"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label text-left">
                Code postal
              </label>
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="currentUserPersonalInfo.zipcode"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label text-left">
                Localité
              </label>
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-bind:value="currentUserPersonalInfo.locality"
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null
    };
  },
  mounted() {
    this.current_photo = this.currentUserPersonalInfo.photo;
  },
  methods: {
    save() {
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company_name = this.$refs.company_name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var company_site = this.$refs.company_site.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name,
          surname,
          company_name,
          phone,
          email,
          company_site,
          photo
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
